<template>
    <div class="pt-7 container-fluid">
        <b-card class="mb--4">
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <b-row class="filterSection">
                <b-col md="9" class="mb-4">
                    <base-button type="success" @click="addNew" class="my--5">
                        <i class="fas fa-plus"></i> {{$t('general.addnew')}}
                    </base-button>
                </b-col>
                <b-col md="3">
                    <b-form-group v-slot="{ariaDescribedby}">
                        <b-form-radio-group
                            v-model="view"
                            :options="[
                                {text: 'kart', value: 'card'},
                                {text: 'tablo', value: 'table'}
                            ]"
                            :aria-describedby="ariaDescribedby"
                            buttons
                            size="sm"
                            button-variant="outline-success"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('doctor.search_area')" v-model="filter.text" @input="searchTimeOut">
                    </base-input>
                </b-col>
                <b-col md="2" v-if="userType!='clinic'">
                    <base-input :label="$t('general.institution')">
                        <el-select v-model="filter.institution" :placeholder="$t('general.select')" clearable @change="getData(true)">
                            <el-option v-for="(item, key) in ['private', 'clinic']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2" v-if="userType!='clinic'">
                    <base-input :label="$t('general.clinic')">
                        <el-select v-model="filter.clinic" :placeholder="$t('general.select')" clearable @change="getData(true)">
                            <el-option v-for="(item, key) in clinics" :key="key" :label="item.name" :value="item._id" v-if="!item.privateClinic"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.branch')">
                        <el-select v-model="filter.branch" :placeholder="$t('general.select')" clearable @change="getData(true)">
                            <el-option v-for="(item, key) in branchList" :key="key" :label="item.name[$i18n.locale]" :value="item._id"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="1">
                    <base-input :label="$t('general.status')">
                        <el-select v-model="filter.status" :placeholder="$t('general.select')" clearable @change="getData(true)">
                            <el-option :label="$t('general.active')" :value="true"></el-option>
                            <el-option :label="$t('general.passive')" :value="false"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.city')">
                        <el-select v-model="filter.city" :placeholder="$t('general.select')" clearable @change="getData(true)">
                            <el-option v-for="(item, key) in cities" :key="key" :label="item.name" :value="item._id"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body v-if="doctors" class="p-4">
            <div v-if="view == 'card'">
				<b-row>
					<b-col xl="3" lg="4" md="6" sm="12" class="mb-3" v-for="(doctor, index) in doctors" :key="index">
						<b-card no-body sub-title="Card subtitle" header="featured" header-tag="header">
							<template #header>
								<b-row>
									<b-col
										md="12"
										v-b-tooltip.hover.html
										:title="`${$t('general.created_date')}<br>${$options.filters.getDate(doctor.createdAt)}`"
									>
										<h4 class="mb-2">{{ doctor.title }} {{ doctor.name }}</h4>
									</b-col>
									<b-col md="6">
										<div class="avatarSection" @click="addPhoto(doctor)">
											<b-badge variant="success" class="badge-circle badge-md badge-floating border-white">
												<i class="fas fa-plus"></i>
											</b-badge>
											<b-avatar variant="primary" v-if="!doctor.photo"></b-avatar>
											<b-avatar v-else :src="imgUrl + doctor.photo"></b-avatar>
										</div>
									</b-col>
									<b-col md="6" class="text-right">
										<badge class="badge-dot" type="">
											<i :class="`bg-${status[doctor.status]}`"></i>
											<span class="status" :class="`text-${status[doctor.status]}`">{{
												doctor.status ? $t('general.active') : $t('general.passive')
											}}</span>
										</badge>
									</b-col>
								</b-row>
							</template>
							<b-card-body style="max-height: 150px; height: 150px">
								<b-card-title style="font-size: 0.7rem">
									<b-row>
										<b-col md="6">
											<div v-if="doctor.institution == 'clinic'">{{ $t('general.clinic') }}</div>
											<div v-else>{{ $t('general.private') }}</div>
										</b-col>
										<b-col md="6"> {{ doctor.city | showCity }} / {{ doctor.country | showCountry }} </b-col>
									</b-row>
								</b-card-title>
								<b-card-sub-title>
									<div v-if="doctor.clinic">{{ $options.filters.showClinic(doctor.clinic) }}</div>
									<div v-else>-</div>
								</b-card-sub-title>
								<b-card-text>
									<small v-html="$options.filters.showBranch(doctor.branch)"></small>
								</b-card-text>
							</b-card-body>
							<b-list-group flush>
								<b-list-group-item class="p-2">
									<small v-if="doctor.phone && doctor.phone.trim() != ''">+{{ doctor.phone }}</small
									><br />
									<small>{{ doctor.email }}</small>
								</b-list-group-item>
							</b-list-group>
							<template #footer>
								<b-row>
									<b-col md="6">
										<base-button type="primary" block size="sm" @click="handleEdit(doctor)">{{ $t('general.edit') }}</base-button>
									</b-col>
									<b-col md="6">
										<base-button type="danger" block size="sm" @click="handleDelete(doctor.uuid)">{{ $t('general.delete') }}</base-button>
									</b-col>
								</b-row>
							</template>
						</b-card>
					</b-col>
				</b-row>
			</div>
            <div v-if="view == 'table'">
                <el-table class="table-responsive table" header-row-class-name="thead-light" :data="doctors" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')" :row-class-name="tableRowClassName">
                    <el-table-column min-width="60" label="" class-name="doctorAvatar">
                        <template v-slot="{row}">
                            <div class="avatarSection" @click="addPhoto(row)">
                                <b-badge variant="success" class="badge-circle badge-md badge-floating border-white">
                                    <i class="fas fa-plus"></i>
                                </b-badge>
                                <b-avatar variant="primary" v-if="!row.photo"></b-avatar>
                                <b-avatar v-else :src="imgUrl+row.photo"></b-avatar>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('general.name')" prop="name" min-width="280" width="auto">
                        <template v-slot="{row}">
                            <span>{{row.title}} {{row.name}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('general.institution')" prop="institution" min-width="160">
                        <template v-slot="{row}">
                            <small v-if="row.institution=='clinic'">{{$t('general.clinic')}}</small>
                            <small v-else>{{$t('general.private')}}</small>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('general.clinic')" prop="clinic" min-width="200">
                        <template v-slot="{row}">
                            <small v-html="$options.filters.showClinic(row.clinic)" v-if="row.clinic!=''"></small>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('general.branch')" prop="branch" min-width="220">
                        <template v-slot="{row}">
                            <small v-html="$options.filters.showBranch(row.branch)"></small>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('general.email')" prop="email" min-width="240">
                        <template v-slot="{row}">
                            <span>{{row.email}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('general.phone')" prop="phone" min-width="185">
                        <template v-slot="{row}">
                            <span v-if="row.phone&&row.phone.trim()!=''">+{{row.phone}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('general.country')" prop="country" min-width="160">
                        <template v-slot="{row}">
                            {{row.country | showCountry}}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('general.city')" prop="city" min-width="160">
                        <template v-slot="{row}">
                            {{row.city | showCity}}
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('general.status')" prop="status" min-width="140">
                        <template v-slot="{row}">
                            <badge class="badge-dot mr-4" type="">
                                <i :class="`bg-${status[row.status]}`"></i>
                                <span class="status" :class="`text-${status[row.status]}`">{{row.status?$t('general.active'):$t('general.passive')}}</span>
                            </badge>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="210" sortable>
                        <template v-slot="{row}">
                            {{row.createdAt | getDate}}
                        </template>
                    </el-table-column>
                    <el-table-column align="right" min-width="140">
                        <template slot-scope="scope">
                            <base-button type="primary" size="sm" @click="openProcessModal(scope.$index)">{{$t('general.process')}}</base-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose" size="lg">
            <validation-observer v-slot="{handleSubmit, invalid, errors}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data.uuid ? $t('doctor.edit') : $t('doctor.add')}}</h6>
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading" novalidate>
                    <hr>
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1 ovisible">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.pinfo variant="default">{{$t('general.personal_info')}}</b-button>
                            </b-card-header>
                            <b-collapse id="pinfo" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="2">
                                            <label class="form-control-label">{{$t('general.status')}}</label>
                                            <b-form-checkbox v-model="modal.data.status" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.status ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="2">
                                            <base-input :label="$t('general.gender')+'*'">
                                                <el-select v-model="modal.data.gender" name="gender" :nameAs="$t('general.gender')" :placeholder="$t('general.select')" required>
                                                    <el-option v-for="item in ['male', 'female']" :key="item" :label="$t('general.'+item)" :value="item"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col md="2">
                                            <base-input type="text" name="title" :nameAs="$t('doctor.title')" :label="$t('doctor.title')" :placeholder="$t('doctor.title')" v-model="modal.data.title">
                                            </base-input>
                                        </b-col>
                                        <b-col md="3">
                                            <base-input type="text" name="name" :nameAs="$t('general.name')" :label="$t('general.name')+'*'" :placeholder="$t('general.name')" @input="generateSeo('name')" v-model="modal.data.name" required>
                                            </base-input>
                                        </b-col>
                                        <b-col md="3">
                                            <base-input type="text" name="seo" :nameAs="$t('general.seo')" :label="$t('general.seo')+'*'" :placeholder="$t('general.seo')" @input="generateSeo('seo')" v-model="modal.data.seo" required>
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="4">
                                            <base-input :label="$t('general.branch')+'*'" name="branch" :nameAs="$t('general.branch')" :rules="'required'">
                                                <el-select v-model="modal.data.branch" :placeholder="$t('general.select')" multiple collapse-tags>
                                                    <el-option v-for="(item, key) in branchList" :key="key" :label="item.name[$i18n.locale]" :value="item._id"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col md="4" v-if="userType!='clinic'">
                                            <base-input :label="$t('general.institution')+'*'">
                                                <el-select v-model="modal.data.institution" name="institution" :nameAs="$t('general.institution')" :placeholder="$t('general.select')" :disabled="userType=='clinic'" required>
                                                    <el-option v-for="item in ['clinic', 'private']" :key="item" :label="$t('general.'+item)" :value="item"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col md="4" v-if="modal.data.institution!='private' && userType!='clinic'">
                                            <base-input :label="$t('general.clinic')+'*'" name="clinic" :nameAs="$t('general.clinic')" :rules="'required'">
                                                <el-select v-model="modal.data.clinic" :placeholder="$t('general.select')" :disabled="modal.data.institution=='private'" required>
                                                    <el-option v-for="(item, key) in clinics" :key="key" :label="item.name" :value="item._id"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col md="4">
                                            <base-input :label="$t('general.known_languages')+'*'" name="branch" :nameAs="$t('general.known_languages')" :rules="'required'">
                                                <el-select v-model="modal.data.languages" :placeholder="$t('general.select')" filterable multiple>
                                                    <el-option v-for="(item, key) in languages" :key="key" :label="item.nativeName" :value="item.code"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="6">
                                            <label class="form-control-label">{{$t('profile.phone')}}*</label>
                                            <VuePhoneNumberInput 
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                v-model="modal.data.maskPhone" 
                                                required 
                                                class="mb-2" 
                                                :translations="{
                                                    example: $t('general.example'), countrySelectorError: $t('error.select_country'), countrySelectorLabel: $t('general.country_code'), phoneNumberLabel: $t('general.phone_number')
                                                }" 
                                                :defaultCountryCode="modal.data.defaultCountryCode"
                                                show-code-on-list
                                                @update="onPhone"
                                                no-flags />
                                        </b-col>
                                        <b-col md="6">
											<label class="form-control-label">{{ $t('profile.officePhone') }}*</label>
											<VuePhoneNumberInput
												id="officeNumber"
                                                name="officeNumber"
												v-model="modal.data.officePhone.number"
												required
												class="mb-2"
												:translations="{
													example: $t('general.example'),
													countrySelectorError: $t('error.select_country'),
													countrySelectorLabel: $t('general.country_code'),
													phoneNumberLabel: $t('general.phone_number')
												}"
												:defaultCountryCode="modal.data.defaultCountryCode"
												show-code-on-list
												@update="onOfficePhone"
												no-flags
											/>
										</b-col>
                                        <b-col md="6">
                                            <base-input type="email" name="email" :nameAs="$t('profile.email')" :label="$t('profile.email')+'*'" :placeholder="$t('profile.email')" v-model="modal.data.email" required>
                                            </base-input>
                                        </b-col>
                                        <b-col md="6">
											<base-input
												type="text"
												name="webSite"
												:nameAs="$t('profile.website')"
												:label="$t('profile.website') + '*'"
												:placeholder="$t('profile.website')"
												v-model="modal.data.webSite"
											>
											</base-input>
										</b-col>
                                        <b-col md="6">
                                            <label class="form-control-label">{{$t('general.password')}}*
                                                <template v-if="profile.superadmin&&modal.data.temp">
                                                    <small>(
                                                        <strong>{{modal.data.temp}}</strong> )</small>
                                                </template>
                                            </label>
                                            <base-input type="password" name="password" :nameAs="$t('general.password')" :placeholder="$t('general.password')" v-model="modal.data.password" v-if="modal.data.uuid" :rules="'min:6'"></base-input>
                                            <base-input type="password" name="password" :nameAs="$t('general.password')" :placeholder="$t('general.password')" v-model="modal.data.password" :rules="'required|min:6'" v-else></base-input>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12">
                                            <b-form-group>
                                                <b-tabs content-class="mt-3">
                                                    <b-tab :active="key==0" :title="lang.toUpperCase()" v-for="(lang,key) in setting.lang" :key="key">
                                                        <validation-provider :rules="'max:200'" :name="$t('general.short_desc')" v-slot="{ errors }">
                                                            <label class="form-control-label">{{$t('general.short_desc')}}</label>
                                                            <b-form-textarea :class="errors[0] ? 'is-invalid' : ''" :placeholder="$t('general.short_desc')" rows="5" no-resize v-model="modal.data.descText[lang]" @input.native="clearSpace('descText', lang)" @blur="clearSpace('descText', lang)" @keyup="clearSpace('descText', lang)"></b-form-textarea>
                                                            <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </validation-provider>
                                                        <small class="text-default">Kısa tanıtım yazısı doktor listeleme ekranında yer alacaktır. Düze metin olarak maksimum 200 karakter olmalıdır.</small>
                                                        <hr class="my-2">
                                                        <label class="form-control-label">{{$t('general.doctor_desc')}}</label>
                                                        <quill-editor v-model="modal.data.desc[lang]" :options="editorOption" />
                                                    </b-tab>
                                                </b-tabs>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.vocational variant="default">{{ $t('general.vocational_info') }}</b-button>
							</b-card-header>
							<b-collapse id="vocational" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-row>
										<b-col md="12">
                                            <VueBootstrapTypeahead
												ref="selectedDisease"
												v-model="queryDiseases"
												:data="diseases"
												:serializer="s => getLangItem(s.name)"
												@hit="selectedDisease=$event"
												@input="inputDisease"
												:placeholder="$t('general.disease_interest')"
											>
												<template slot="append">
													<b-button @click="addDisease" variant="primary" v-if="selectedDisease">
													{{$t('general.add')}}
													</b-button>
												</template>
											</VueBootstrapTypeahead>
											<div class="mt-1">
												<b-badge variant="success"  v-for="(item, index) in modal.data.diseases" :key="index" class="ml-1">
														{{getLangItem(item.name)}} <b-link @click="removeDisease(item._id)"><i class="fal fa-times"></i></b-link>
												</b-badge>
											</div>
											<hr>
											<VueBootstrapTypeahead
												ref="selectedTreatment"
												v-model="queryTreatments"
												:data="treatments"
												:serializer="s => getLangItem(s.name)"
												@hit="selectedTreatment=$event"
												@input="inputTreatment"
												:placeholder="$t('general.treatments')"
											>
												<template slot="append">
													<b-button @click="addTreatment" variant="primary" v-if="selectedTreatment">
													{{$t('general.add')}}
													</b-button>
												</template>
											</VueBootstrapTypeahead>
											<div class="mt-1">
												<b-badge variant="success"  v-for="item in modal.data.treatments" :key="item._id" class="ml-1">
														{{getLangItem(item.name)}} <b-link @click="removeTreatment(item._id)"><i class="fal fa-times"></i></b-link>
												</b-badge>
											</div>
                                            <hr>
											<base-input
												type="text"
												:label="$t('general.diploma_institution')"
												:placeholder="$t('general.schoolname')"
												v-model="modal.data.vocational.universityOfDiploma"
												prependIcon="fas fa-graduation-cap"
											>
											</base-input>

											<base-input
												type="text"
												:label="$t('general.specialization_institution')"
												:placeholder="$t('general.schoolname')"
												v-model="modal.data.vocational.institutionOfSpecialization"
												prependIcon="fas fa-graduation-cap"
											>
											</base-input>

											<label for="example-input">{{$t('general.service_start')}}</label>
											<b-input-group class="mb-3">
												<b-form-input
													id="example-input"
													v-model="modal.data.vocational.careerStart"
													type="text"
													placeholder="DD.MM.YYYY"
													autocomplete="off"
												></b-form-input>
											</b-input-group>
										</b-col>
									</b-row>
								</b-card-body>
							</b-collapse>
						</b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.address variant="default">{{$t('general.address_info')}}</b-button>
                            </b-card-header>
                            <b-collapse id="address" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="6">
                                            <base-input type="text" name="address" :nameAs="$t('general.address')" :label="$t('general.address')" :placeholder="$t('general.address')" v-model="modal.data.address">
                                            </base-input>
                                        </b-col>
                                        <b-col md="3">
                                            <base-input :label="$t('general.country')+'*'" name="country" :nameAs="$t('general.country')" :rules="'required'">
                                                <el-select v-model="modal.data.country" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                                    <el-option v-for="(item, key) in countries" :key="key" :label="item.name" :value="item._id"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col md="3">
                                            <base-input :label="$t('general.city')+'*'" name="city" :nameAs="$t('general.city')" :rules="'required'">
                                                <el-select v-model="modal.data.city" filterable :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" :placeholder="$t('general.select')" @change="changeCity" required>
                                                    <el-option v-for="(item, key) in cities" :key="key" :label="item.name" :value="item._id"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col>
                                            <div class="simple-map">
                                                <GmapMap :center.sync="center" :options="mapOptions" map-type-id="terrain" @click="markerDrag">
                                                    <GmapMarker :position="modal.data.location" :clickable="true" :draggable="true" @dragend="markerDrag" v-if="modal.data.location.lat>0" />
                                                </GmapMap>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1" v-if="modal.data.uuid">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.photos variant="default">{{ $t('general.photos') }}</b-button>
							</b-card-header>
							<b-collapse id="photos" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-form-file
										multiple
										accept="image/jpeg, image/png"
										size="md"
										v-model="photos.list"
										:placeholder="$t('general.select_img')"
										:drop-placeholder="$t('general.drop_file')"
										:browse-text="$t('general.choose')"
										ref="photos"
									>
										<template slot="file-name" slot-scope="{names}">
											<b-badge variant="dark">{{ names[0] }}</b-badge>
											<b-badge v-if="names.length > 1" variant="dark" class="ml-1"> + {{ names.length - 1 }} More files </b-badge>
										</template>
									</b-form-file>
									<p>
										<br />
										<base-button type="primary" size="sm" @click="uploadPictures(modal.data.uuid, 'photos')" :disabled="photos.list.length==0">{{
											$t('general.upload')
										}}</base-button>
									</p>

									<b-row>
										<b-col md="3" v-for="(picture, index) in modal.data.photos" :key="index">
											<b-card no-body class="mt-2">
												<img :src="imgUrl + picture" alt="" />
												<template #footer>
													<base-button type="danger" block size="sm" @click="removePicture(modal.data.uuid, picture, 'photos')">{{
														$t('general.delete')
													}}</base-button>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-card-body>
							</b-collapse>
						</b-card>
                        <b-card no-body class="mb-1" v-if="modal.data.uuid">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.diplomasAndCertificates variant="default">{{ $t('general.diplomasAndCertificates') }}</b-button>
							</b-card-header>
							<b-collapse id="diplomasAndCertificates" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-form-file
										multiple
										accept="image/jpeg, image/png"
										size="md"
										v-model="diplomasAndCertificates.list"
										:placeholder="$t('general.select_img')"
										:drop-placeholder="$t('general.drop_file')"
										:browse-text="$t('general.choose')"
										ref="diplomasAndCertificates"
									>
										<template slot="file-name" slot-scope="{names}">
											<b-badge variant="dark">{{ names[0] }}</b-badge>
											<b-badge v-if="names.length > 1" variant="dark" class="ml-1"> + {{ names.length - 1 }} More files </b-badge>
										</template>
									</b-form-file>
									<p>
										<br />
										<base-button type="primary" size="sm" @click="uploadPictures(modal.data.uuid, 'diplomasAndCertificates')" :disabled="diplomasAndCertificates.list.length==0">{{
											$t('general.upload')
										}}</base-button>
									</p>

									<b-row>
										<b-col md="3" v-for="(picture, index) in modal.data.diplomasAndCertificates" :key="index">
											<b-card no-body class="mt-2">
												<img :src="imgUrl + picture" alt="" />
												<template #footer>
													<base-button
														type="danger"
														block
														size="sm"
														@click="removePicture(modal.data.uuid, picture, 'diplomasAndCertificates')"
														>{{ $t('general.delete') }}</base-button
													>
												</template>
											</b-card>
										</b-col>
									</b-row>
								</b-card-body>
							</b-collapse>
						</b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.conversation variant="default">{{$t('general.conversation_settings')}}</b-button>
                            </b-card-header>
                            <b-collapse id="conversation" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.conversation_office')}}</label>
                                            <b-form-checkbox v-model="modal.data.office" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.office ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.conversation_online')}}</label>
                                            <b-form-checkbox v-model="modal.data.online" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.online ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.conversation_vonage')}}</label>
                                            <b-form-checkbox v-model="modal.data.vonage" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.vonage!==undefined&&modal.data.vonage ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.social variant="default">{{$t('general.social_info')}}</b-button>
                            </b-card-header>
                            <b-collapse id="social" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="6" class="text-capitalize">
                                            <base-input type="text" label="Facebook" :placeholder="$t('general.account_url')" v-model="modal.data.social.facebook" :prependIcon="'fab fa-facebook'">
                                            </base-input>
                                        </b-col>
                                        <b-col md="6" class="text-capitalize">
                                            <base-input type="text" label="Instagram" :placeholder="$t('general.account_url')" v-model="modal.data.social.instagram" :prependIcon="'fab fa-instagram'">
                                            </base-input>
                                        </b-col>
                                        <b-col md="6" class="text-capitalize">
                                            <base-input type="text" label="Twitter" :placeholder="$t('general.account_url')" v-model="modal.data.social.twitter" :prependIcon="'fab fa-twitter'">
                                            </base-input>
                                        </b-col>
                                        <b-col md="6" class="text-capitalize">
                                            <base-input type="text" label="Youtube" :placeholder="$t('general.account_url')" v-model="modal.data.social.youtube" :prependIcon="'fab fa-youtube'">
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.confirmation variant="default">{{$t('general.confirmation_info')}}</b-button>
                            </b-card-header>
                            <b-collapse id="confirmation" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.confirmation_user')}}</label>
                                            <b-form-checkbox v-model="modal.data.confirmation.user" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.confirmation.user ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.confirmation_email')}}</label>
                                            <b-form-checkbox v-model="modal.data.confirmation.email" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.confirmation.email ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.confirmation_phone')}}</label>
                                            <b-form-checkbox v-model="modal.data.confirmation.phone" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.confirmation.phone ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.medisoft variant="default">{{$t('general.medisoft_settings')}}</b-button>
                            </b-card-header>
                            <b-collapse id="medisoft" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.medisoft_entegration')}}</label>
                                            <b-form-checkbox v-model="modal.data.medisoft.status" class="switchCheck dblock" name="check-button" @change="getMedisoftDoctorList" switch>
                                                {{modal.data.medisoft.status ? $t('general.active') : $t('general.passive')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="6" v-loading="medisoft.loading&&modal.data.medisoft.status">
                                           <base-input :label="$t('general.doctor')" name="medisoft_doctor" :nameAs="$t('general.doctor')" v-if="medisoft.list.length>0&&modal.data.medisoft.status">
                                                <el-select v-model="modal.data.medisoft.id" filterable clearable :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" :placeholder="$t('general.select')">
                                                    <el-option v-for="(item, key) in medisoft.list" :key="key" :label="item.DoctorName+' - '+item.SpecialityName" :value="item.DoctorId"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.frontend variant="default">{{$t('general.show_frontend')}}</b-button>
                            </b-card-header>
                            <b-collapse id="frontend" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.social_info')}}</label>
                                            <b-form-checkbox v-model="modal.data.show.social" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.show.social ? $t('general.show') : $t('general.hide')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.address_info')}}</label>
                                            <b-form-checkbox v-model="modal.data.show.address" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.show.address ? $t('general.show') : $t('general.hide')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.email_info')}}</label>
                                            <b-form-checkbox v-model="modal.data.show.email" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.show.email ? $t('general.show') : $t('general.hide')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.phone_info')}}</label>
                                            <b-form-checkbox v-model="modal.data.show.phone" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.show.phone ? $t('general.show') : $t('general.hide')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('general.map_info')}}</label>
                                            <b-form-checkbox v-model="modal.data.show.map" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.show.map ? $t('general.show') : $t('general.hide')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('profile.officePhone')}}</label>
                                            <b-form-checkbox v-model="modal.data.show.officePhone" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.show.officePhone ? $t('general.show') : $t('general.hide')}}
                                            </b-form-checkbox>
                                        </b-col>
                                        <b-col md="3">
                                            <label class="form-control-label">{{$t('profile.website')}}</label>
                                            <b-form-checkbox v-model="modal.data.show.webSite" class="switchCheck dblock" name="check-button" switch>
                                                {{modal.data.show.webSite ? $t('general.show') : $t('general.hide')}}
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.commissions variant="default">{{$t('general.comission_setting')}}</b-button>
                            </b-card-header>
                            <b-collapse id="commissions" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col md="3">
                                            <base-input type="text" name="commission" :nameAs="$t('doctor.commission_ratio')" :label="$t('doctor.commission_ratio')+'*'" :placeholder="$t('doctor.commission_ratio')" v-model="modal.data.commission" :prependIcon="'fas fa-percent'" :rules="{required: true, regex: /^\d*\.?\d*$/}">
                                            </base-input>
                                        </b-col>
                                        <b-col md="3">
                                            <base-input :label="$t('general.bank')" name="bank" :nameAs="$t('general.bank')">
                                                <el-select v-model="modal.data.paymentInfo.bank" filterable clearable :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" :placeholder="$t('general.select')">
                                                    <el-option v-for="(item, key) in bankList" :key="key" :label="$i18n.t('bankList.'+item)" :value="item"></el-option>
                                                </el-select>
                                            </base-input>
                                        </b-col>
                                        <b-col md="6" v-if="modal.data.paymentInfo.bank && modal.data.paymentInfo.bank!=''">
                                            <base-input v-mask="'AA## #### #### #### #### #### ##'" type="text" label="IBAN" name="iban" :nameAs="$t('general.iban')" :placeholder="$t('general.iban')" v-model="modal.data.paymentInfo.iban" required>
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                    <hr>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modalClose" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
        <modal :show.sync="photoModal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="photoModalClose">
            <h6 slot="header" class="modal-title">{{$t('general.image_upload')}}</h6>
            <div class="imgSection text-center" v-loading="photoModal.loading">
                <div class="emptyPhoto" v-if="!photoModal.data.photo">
                    <i class="fas fa-user"></i>
                </div>
                <div class="profilPhoto" v-else>
                    <img :src="imgUrl+photoModal.data.photo" alt="">
                </div>
                <b-form-file accept="image/jpeg, image/png" size="sm" v-model="photoModal.data.upload" :placeholder="$t('general.select_img')" :drop-placeholder="$t('general.drop_file')" :browse-text="$t('general.choose')" ref="file" @change="handleFileUpload"></b-form-file>
                <p><br>
                    <base-button type="primary" size="sm" v-if="photoModal.data.upload" @click="uploadPhoto">{{$t('general.upload')}}</base-button>
                </p>
            </div>
            <base-button type="danger" size="sm" class="ml-auto float-right" @click="photoModalClose">{{$t('general.close')}}</base-button>
        </modal>

        <modal :show.sync="processModal.status" modal-classes="modal-danger" modal-content-classes="bg-gradient-primary" size="sm">
            <h6 slot="header" class="modal-title">{{$t('general.process')}}</h6>

            <div class="py-3 text-center">
                <base-button type="secondary" block size="sm" @click="doProcess('edit')">{{$t('general.edit')}}</base-button>
                <base-button type="secondary" block size="sm" @click="doProcess('delete')">{{$t('general.delete')}}</base-button>
            </div>

            <template slot="footer">
                <base-button type="link" class="text-white ml-auto" @click="processModal.status = false">{{$t('general.close')}}</base-button>
            </template>

        </modal>
    </div>
</template>

<script>
import Vue from 'vue'
import { findPhoneNumbersInText } from 'libphonenumber-js';
import VuePhoneNumberInput from '@/components/PhoneInput';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import getSlug from 'speakingurl';
import _ from 'lodash';
//import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { CLINIC_REQUEST, DOCTOR_REQUEST, DOCTOR_UPDATE, DOCTOR_ADD, DOCTOR_DELETE, DOCTOR_UPLOAD } from '@/store/modules/define';
import { handleError } from '@/utils/func';
//import { getCookie } from '@/utils/cookie';
import { setTimeout } from 'timers';

let self;
let emptyModal = {
    status: true,
	office: true,
	online: true,
	vonage: true,
	title: '',
	name: '',
	seo: '',
	gender: 'male',
	branch: '',
	clinic: '',
	institution: 'private',
	desc: {},
	descText: {},
	email: '',
	photos: [],
	diplomasAndCertificates: [],
	defaultCountryCode: null,
	countryCode: '',
	phone: '',
	maskPhone: '',
	officePhone: {
		code: '',
		number: ''
	},
	country: '6007eee0aaf082368c83d163',
	city: '',
	address: '',
	webSite: '',
	location: {lat: 0, lng: 0},
	password: '',
	vocational: {
		universityOfDiploma: '',
		institutionOfSpecialization: '',
		careerStart: ''
	},
    diseases: [],
    treatments: [],
	social: {
		facebook: '',
		instagram: '',
		twitter: '',
		youtube: ''
	},
    medisoft: {
        id: '',
        status: false
    },
	confirmation: {
		user: false,
		email: false,
		phone: false
	},
	show: {
		social: false,
		address: false,
		email: false,
		phone: false,
		map: false,
		officePhone: false,
		webSite: false,
	},
	commission: 0,
	paymentInfo: {
		bank: '',
		iban: ''
	},
    languages:[]
};
export default {
    components: { VuePhoneNumberInput, VueBootstrapTypeahead },
    computed: {
        ...mapGetters({
            doctors: 'getDoctors',
            total: 'getDoctorTotal',
            pages: 'getDoctorPages',
            clinics: 'getClinics',
            clinic: 'getClinic',
            branchList: 'getBranchList',
            countries: 'getCountry',
            cities: 'getCity',
            userType: 'getUserType',
            profile: 'getProfile',
            setting: 'generalSetting'
        }),
        ...mapState('disease', {diseases: 'records'}),
		...mapState('treatment', {treatments: 'records'}),
		...mapState('extra', {languages: 'languages'}),
    },
    data() {
        const mapOptions = this.$root.defaultMapOptions;
        return {
            loadingPage: false,
            medisoft: {
                loading: false,
                list: []
            },
            view: 'table',
            editorOption: {
                modules: {
                    clipboard: {
                        matchVisual: true
                    },
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ['clean'],
                        ['link', 'image']
                    ]
                },
                placeholder: ''
            },
            bankList: [
                'halkbank',
                'vakifbank',
                'ziraat',
                'akbank',
                'anadolubank',
                'fibabanka',
                'sekerbank',
                'turkish_bank',
                'turk_ekonomi_bankasi',
                'turkiye_is_bankasi',
                'yapi_kredi_bankasi',
                'adabank',
                'citibank',
                'denizbank',
                'garanti',
                'hsbc',
                'ing_bank',
                'odeabank',
                'finansbank'
            ],
            processModal: {
                status: false,
                index: false
            },
            imgUrl: process.env.VUE_APP_CDNURL + '/profile/',
            file: '',
            center: mapOptions.center,
            mapOptions: mapOptions,
            modal: {
                loading: false,
                status: false,
                showHtml: false,
                data: _.cloneDeep(emptyModal)
            },
            photoModal: {
                loading: false,
                status: false,
                data: {}
            },
            photos: {
				loading: false,
				list: [],
				files: null
			},
			diplomasAndCertificates: {
				loading: false,
				list: [],
				files: null
			},
            loading: true,
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {},
            queryDiseases: '',
			selectedDisease: null,
			queryTreatments: '',
			selectedTreatment: null
        };
    },
    methods: {
        inputDisease(val){
			if(val == ''){
				this.selectedDisease = null
			}
		},
		addDisease(){
			this.modal.data.diseases.push(this.selectedDisease)
			this.$store.commit('disease/setRecords', [])
			this.$refs.selectedDisease.inputValue = ''
			this.queryDiseases = ''
			this.selectedDisease = ''
		},
		removeDisease(_id){
			Vue.delete(this.modal.data.diseases, _.findIndex(this.modal.data.diseases, {_id}))
		},
		inputTreatment(val){
			if(val == ''){
				this.selectedTreatment = null
			}
		},
		addTreatment(){
			this.modal.data.treatments.push(this.selectedTreatment)
			this.$store.commit('treatment/setRecords', [])
			this.$refs.selectedTreatment.inputValue = ''
			this.queryTreatments = ''
			this.selectedTreatment = ''
		},
		removeTreatment(_id){
			Vue.delete(this.modal.data.treatments, _.findIndex(this.modal.data.treatments, {_id}))
		},
        clearSpace(item, lang) {
            let text = _.clone(self.modal.data.descText[lang]);
            text = text.replace(/(?:\r\n|\r|\n)/g, ' ');
            text = text.replace(/ +(?= )/g, '');
            self.modal.data.descText[lang] = _.clone(text);
        },
        onPhone(payload) {
            if (payload.isValid) {
                this.$set(self.modal.data, 'countryCode', payload.countryCallingCode);
                this.$set(self.modal.data, 'phone', payload.nationalNumber);
            } else {
                this.$set(self.modal.data, 'countryCode', '');
                this.$set(self.modal.data, 'phone', '');
            }
        },
        onOfficePhone(payload) {
			if (payload.isValid) {
				this.$set(self.modal.data.officePhone, 'code', payload.countryCallingCode)
				this.$set(self.modal.data.officePhone, 'number', payload.nationalNumber)
			} else {
				this.$set(self.modal.data.officePhone, 'code', '')
				this.$set(self.modal.data.officePhone, 'number', '')
			}
		},
        doProcess(process) {
            self.processModal.status = false;
            let row = self.doctors[self.processModal.index];
            switch (process) {
                case 'edit':
                    self.handleEdit(row);
                    break;
                case 'delete':
                    self.handleDelete(row.uuid);
                    break;
                default:
                    break;
            }
        },
        openProcessModal(index) {
            self.processModal.index = index;
            self.processModal.status = true;
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData(true);
            }, 800);
        },
        handleFileUpload() {
            self.file = this.$refs.file.files[0];
        },
        uploadPhoto() {
            self.photoModal.loading = true;
            self.$store
                .dispatch(DOCTOR_UPLOAD, { file: self.photoModal.data.upload, user: self.photoModal.data.user })
                .then(resp => {
                    self.photoModal.loading = false;
                    self.photoModal.status = false;
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.photoModal.loading = false;
                    handleError(self, err);
                });
        },
        async uploadPictures(uuid, type) {
			this.modal.loading = true
			try {
				var response = await this.$store.dispatch('DOCTOR_PICTUREUPLOAD', {files: this[type].list, user: uuid, type})
				if (response.status) {
					var countPictures = this.modal.data[type].length
					response.pictures.forEach((item, index) => {
						Vue.set(this.modal.data[type], countPictures + index, item)
					})
				}
				this.$notify({type: 'success', message: this.$i18n.t('success.positive')})
				this.$refs[type].reset()
				this.modal.loading = false
			} catch (err) {
				this.modal.loading = false
				handleError(this, err)
			}
		},
		async removePicture(user, picture, type) {
			const result = await this.$swal({
				title: this.$i18n.t('quest.sure'),
				text: this.$i18n.t('quest.delete_info'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5365c',
				confirmButtonText: this.$i18n.t('quest.delete'),
				cancelButtonText: this.$i18n.t('quest.cancel')
			})
			if (result.value) {
				this.loading = true
				try {
					await this.$store.dispatch('DOCTOR_PICTUREDELETE', {user, picture, type})
					this.loading = false
					this.$delete(this.modal.data[type], this.modal.data[type].indexOf(picture))
					this.$notify({type: 'success', message: self.$i18n.t('success.positive')})
				} catch (err) {
					this.loading = false
					handleError(this, err)
				}
			}
		},
        addPhoto(row) {
            self.photoModal.loading = false;
            self.photoModal.status = true;
            self.photoModal.data = { user: row.uuid, upload: null };
            self.photoModal.data['photo'] = row.photo != null || row.photo != '' ? row.photo : false;
        },
        tableRowClassName({ row, rowIndex }) {
            if (!row.status) {
                return 'table-danger';
            }
            return '';
        },
        generateSeo(key) {
            let lang = self.$i18n.locale;
            self.modal.data.seo = getSlug(self.modal.data[key], { lang });
        },
        changeCity(value) {
            let city = _.get(
                _.filter(self.cities, item => {
                    return item._id == value;
                }),
                0
            );

            let marker = { lat: parseFloat(city.latitude), lng: parseFloat(city.longitude) };
            _.set(self.modal.data, 'location', marker);
            self.center = _.cloneDeep(marker);
        },
        markerDrag(location) {
            let marker = { lat: location.latLng.lat(), lng: location.latLng.lng() };
            _.set(self.modal.data, 'location', marker);
            self.center = _.cloneDeep(marker);
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getData();
        },
        async handleEdit(row) {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);

            if(this.userType=='clinic' && this.clinic._id!==undefined){
                self.modal.data.institution = 'clinic';
                self.modal.data.clinic = this.clinic._id;
            }

            self.modal.data = _.merge(self.modal.data, row);
            _.forEach(self.modal.data.desc, (v, i) => {
                self.modal.data.desc[i] = v.replace(/[\\]/g, '');
            });
            let parsePhone = findPhoneNumbersInText('+' + self.modal.data.phone.toString());
            let country = parsePhone[0].number.country;
            let phone = parsePhone[0].number.nationalNumber;
            let countryCode = parsePhone[0].number.countryCallingCode;
            this.$set(self.modal.data, 'defaultCountryCode', country);
            this.$set(self.modal.data, 'countryCode', countryCode);
            this.$set(self.modal.data, 'maskPhone', phone);

            if(self.modal.data.medisoft.status) {
                await self.getMedisoftDoctorList();
            }


            if (self.modal.data.location.lat && self.modal.data.location.lat != 0) {
                setTimeout(() => {
                    self.center = _.cloneDeep(self.modal.data.location);
                }, 500);
            }
        },
        handleDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(DOCTOR_DELETE, uuid)
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        photoModalClose() {
            self.photoModal.loading = false;
            self.photoModal.status = false;
            self.photoModal.data = {};
        },
        modalClose() {
            self.modal.loading = false;
            self.modal.status = false;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        addNew() {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);
            if(this.userType=='clinic' && this.clinic._id!==undefined){
                self.modal.data.institution = 'clinic';
                self.modal.data.clinic = this.clinic._id;
            }
        },
        getData(page = false) {
            if (page) {
                self.pagination.page = 1;
            }
            self.loading = true;
            self.$store
                .dispatch(DOCTOR_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        onSubmit() {
            if (self.modal.data.city != '' && self.modal.data.location.lat == 0) {
                self.$notify({ type: 'warning', message: self.$i18n.t('warning.no_location') });

                return false;
            }

            if (self.modal.data.city == '') {
                self.$notify({ type: 'warning', message: self.$i18n.t('warning.no_city') });

                return false;
            }

            if (self.modal.data.uuid != undefined) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onUpdate() {
            let uuid = self.modal.data.uuid;
            let doctor = _.pickBy(self.modal.data, (item, key) => {
                if (key == 'uuid') {
                    return false;
                }

                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            self.modal.loading = true;
            self.$store
                .dispatch(DOCTOR_UPDATE, { uuid, doctor })
                .then(resp => {
                    self.modal.loading = false;
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        onAdd() {
            let doctor = _.pickBy(self.modal.data, (item, key) => {
                switch (typeof item) {
                    case 'boolean':
                        return true;
                        break;
                    case 'string':
                        return item.trim() != '';
                        break;
                    default:
                        return true;
                        break;
                }
            });

            self.modal.loading = true;
            self.$store
                .dispatch(DOCTOR_ADD, doctor)
                .then(resp => {
                    self.modalClose();
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        async getMedisoftDoctorList() {
            if(!self.modal.data.medisoft.status){
                self.modal.data.medisoft.id = null;
            }
            if(self.medisoft.list.length > 0) {
                return false;
            }
            self.medisoft.loading = true;
            await self.$store
                .dispatch('MEDISOFT_DOCTOR_LIST')
                .then(resp => {
                    self.medisoft.loading = false;
                    self.medisoft.list = resp;
                })
                .catch(err => {
                    self.medisoft.loading = false;
                    handleError(self, err);
                });
        },
    },
    filters: {
        showCountry(value) {
            let country = _.get(
                _.filter(self.countries, item => {
                    return item._id == value;
                }),
                0
            );

            return country ? country.name : '';
        },
        showCity(value) {
            let city = _.get(
                _.filter(self.cities, item => {
                    return item._id == value;
                }),
                0
            );

            return city ? city.name : '';
        },
        showBranch(value) {
            let lang = self.$i18n.locale;
            let branches = _.map(
                _.pickBy(self.branchList, item => {
                    return item.status && _.includes(value, item._id);
                }),
                item => {
                    return item.name[lang];
                }
            );

            return _.join(branches, '<br/> ');
        },
        showClinic(value) {
            let clinic = _.find(self.clinics, item => {
                return value == item._id;
            });

            return clinic && clinic.name != undefined ? clinic.name : '';
        }
    },
    watch: {
		queryDiseases: _.debounce(function (value) {
			this.$store.commit('disease/setFilter', {key: 'keyword', value})
			this.$store.dispatch('disease/list')
		}, 500),
		queryTreatments: _.debounce(function (value) {
			this.$store.commit('treatment/setFilter', {key: 'keyword', value})
			this.$store.dispatch('treatment/list')
		}, 500),
	},
    created() {
        self = this;
        self.getData();
        self.$store.dispatch(CLINIC_REQUEST,  { pagination: {limit: 9999,page: 1}, fields: '_id name privateClinic' });
    }
};
</script>
